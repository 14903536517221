import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactModal from "react-modal";
import CloseModal from "./CloseModal";
import useBodyFixed, { modalOpenedBodyClassName } from "./useBodyFixed";

const useDelayedIsOpen = (isOpen, onRequestClose, delay) => {
  const [overridenIsOpen, setOverridenIsOpen] = useState(false);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (isOpen) {
      setTimeout(() => {
        setOverridenIsOpen(true);
      }, delay);
    }
  }, [isOpen, delay]);

  const timeoutRef = useRef();
  const delayedOnRequestClose = (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setOverridenIsOpen(false);
    timeoutRef.current = setTimeout(() => {
      onRequestClose(...args);
    }, delay);
  };

  return [overridenIsOpen ?? isOpen, delayedOnRequestClose];
};

const Modal = (props) => {
  const [delayedIsOpen, onRequestClose] = useDelayedIsOpen(
    props.isOpen,
    props.onRequestClose,
    100
  );
  useBodyFixed(props.fixed);
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={onRequestClose}
      className={classnames("modal", {
        [`modal--${props.appearance}`]: props.appearance,
        [`modal--opened`]: delayedIsOpen === true,
        [`modal--closing`]: delayedIsOpen === false,
        [`modal--${props.name}`]: props.name,
        [`modal--scroll-text`]: props.scrollText,
      })}
      overlayClassName={classnames("modal__overlay", {
        [`modal__overlay--${props.appearance}`]: props.appearance,
        [`modal__overlay--opened`]: delayedIsOpen === true,
        [`modal__overlay--closing`]: delayedIsOpen === false,
      })}
      bodyOpenClassName={modalOpenedBodyClassName}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
    >
      <>
        {!props.dangerouslyIgnoreCloseButton && (
          <CloseModal onClick={onRequestClose} />
        )}
        {
          props.title &&
          <div className="modal__title">
            { props.title }
          </div>
        }
        <div className="modal--body">
          {props.children}
          {props.actions}
        </div>
      </>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  actions: PropTypes.node,
  dangerouslyIgnoreCloseButton: PropTypes.bool,
  appearance: PropTypes.oneOf([
    "default",
    "full",
    "full-no-spacing",
    "full-no-overflow",
    "full-screen-images",
    "no-visible",
    "full-mobile",
    "facet",
    "address",
    "capency",
    "cookie",
  ]),
};

if (typeof window !== "undefined") {
  ReactModal.setAppElement("body");
}

export default Modal;
