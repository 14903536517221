import React from "react";

import AutocompleteOption from "theme/components/organisms/Autocomplete/AutocompleteResults/AutocompleteOption";
import AutocompleteResults from "theme/components/organisms/Autocomplete/AutocompleteResults/AutocompleteResults";
import useId from "../helpers/useId";

const EmailSuggestions = ({
  value,
  names,
  domains,
  selected,
  onSelect,
  onSuggestionSelected,
}) => {
  const id = useId();
  return (
    <AutocompleteResults>
      {names.map((name, i) => (
        <AutocompleteOption
          key={name}
          id={`${id}-name-suggestion-${i}`}
          path={name}
          selected={selected}
          onSelect={onSelect}
          onClick={() => onSuggestionSelected(name)}
        >
          {name}
        </AutocompleteOption>
      ))}
      {domains.map((domain, i) => (
        <AutocompleteOption
          key={domain}
          id={`${id}-domain-suggestion-${i}`}
          path={domain}
          selected={selected}
          onSelect={onSelect}
          onClick={() => onSuggestionSelected(domain)}
        >
          {value.split("@").shift()}@{domain}
        </AutocompleteOption>
      ))}
    </AutocompleteResults>
  );
};

export default EmailSuggestions;

export const renderSuggestions = ({
  value,
  suggestions,
  selected,
  onSelect,
  onSuggestionSelected,
}) => (
  <EmailSuggestions
    {...suggestions}
    value={value}
    selected={selected}
    onSelect={onSelect}
    onSuggestionSelected={onSuggestionSelected}
  />
);
