import React from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import Modal from "./Modal";
import Button from "../../atoms/Button/Button";

const modalMessages = defineMessages({
  warn: {
    id: "components.organisms.Modal.Capency.status.warn.title",
    defaultMessage: "Do you want to keep this email?",
  },
  error: {
    id: "components.organisms.Modal.Capency.status.error.title",
    defaultMessage: "Please change your entry",
  },
  bodyEmail: {
    id: "components.organisms.Modal.Capency.body.mail",
    defaultMessage: "Entered address",
  },
  bodyError: {
    id: "components.organisms.Modal.Capency.body.error",
    defaultMessage: "Error in email address",
  },
  modify: {
    id: "components.organisms.Modal.Capency.button.modify",
    defaultMessage: "Modify",
  },
  keep: {
    id: "components.organisms.Modal.Capency.button.keep",
    defaultMessage: "Keep",
  },
  ok: {
    id: "components.organisms.Modal.Capency.button.ok",
    defaultMessage: "Ok",
  },
});

// Capency Modal alert.
const ModalCapency = ({
  handleSubmitForm,
  isOpen,
  message,
  setIsOpen,
  status,
  userEmail,
}) => {
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const ModalCapencyActionButtons = ({
    status
  }) => {
    switch (status) {
      case "warn":
        return <ModalCapencyWarnActionButtons />;
      default :
        return <ModalCapencyErrorActionButtons />;
    }
  }

  const ModalCapencyWarnActionButtons = () => {
    return (
      <div className="modal__footer">
        <Button
          onClick={handleModalClose}
        >
          <FormattedMessage {...modalMessages.modify} />
        </Button>
        <Button
          onClick={handleSubmitForm}
        >
          <FormattedMessage {...modalMessages.keep} />
        </Button>
      </div>
    )
  };

  const ModalCapencyErrorActionButtons = () => {
    return (
      <div className="modal__footer">
        <Button
          onClick={handleModalClose}
        >
          <FormattedMessage {...modalMessages.ok} />
        </Button>
      </div>
    )
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      appearance="capency"
      isOpen={isOpen}
      onRequestClose={() => null}
      dangerouslyIgnoreCloseButton={true}
      actions={<ModalCapencyActionButtons status={status}/>}
    >
      <div className="modal__header">
        <FormattedMessage {...modalMessages[status || "warn"]} />
      </div>
      <div className="modal__body">
        <div className="modal__body__text">
          {
            status === "error" &&
              <>
                <FormattedMessage {...modalMessages.bodyError} />
                { ' : '}
              </>
          }
          { message }
        </div>
        {
          userEmail &&
            <div className="modal__body__email">
              <FormattedMessage {...modalMessages.bodyEmail} />
              { ' : ' }
              <span className="modal__body__email--address">
                { userEmail }
              </span>
            </div>
        }
      </div>
    </Modal>
  );
};

export default ModalCapency;