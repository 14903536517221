import React, { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";

const messages = defineMessages({
  close: {
    id: "generic.action.close",
    defaultMessage: "Close",
  },
});

class CloseModal extends Component {
  render() {
    return (
      <div className={"modal__close"}>
        <Button
          appearance={"icon"}
          onClick={this.props.onClick}
          title={this.props.intl.formatMessage(messages.close)}
        >
          <Icon icon={"cross"} title={this.props.intl.formatMessage(messages.close)} />
          {this.props.intl.formatMessage(messages.close)}
        </Button>
      </div>
    );
  }
}

export default injectIntl(CloseModal);
