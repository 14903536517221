import React from "react";
import classNames from "classnames";

const Cell = ({ size, children, type }) => {
  const sizeClassName = Array.isArray(size)
    ? size.map((size) => `cell--${size}`).join(" ")
    : `cell--${size}`;
  return (
    <div
      className={classNames(
        "cell",
        { [sizeClassName]: size },
        { [`cell--${type}`]: type }
      )}
    >
      {children}
    </div>
  );
};

export default Cell;
