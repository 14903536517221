import { useEffect } from "react";

export const modalOpenedBodyClassName = "modal__body--opened";

const useBodyFixed = (fixed) => {
  useEffect(() => {
    if (fixed) {
      document.body.classList.add(modalOpenedBodyClassName);
      window.document.documentElement.style.overflow = "auto";
    } else {
      document.body.classList.remove(modalOpenedBodyClassName);
      window.document.documentElement.style.overflow = "auto";
    }

    return () => {
      document.body.classList.remove(modalOpenedBodyClassName);
      window.document.documentElement.style.overflow = "auto";
    };
  }, [fixed]);
};

export default useBodyFixed;
