import { compose, withState, withHandlers } from "recompose";

const EnhanceCapencyModal = () =>
  compose(
    withState("capencyModalState", "setCapencyModalState",
    {
      isLoading: false,
      isOpen: false,
      status: null,
      message: null,
      userEmail: null,
      handleSubmitForm: null,
      elementId: null,
    }),
    withHandlers({
      setCapencyLoading: ({
        setCapencyModalState,
        capencyModalState,
      }) => () => {
        setCapencyModalState({
          ...capencyModalState,
          isLoading: true,
        });
      },
      setCapencyModalIsOpen : ({
        setCapencyModalState,
        capencyModalState,
       }) => (isOpen) => {
        const { elementId } = capencyModalState;
        setCapencyModalState(
          {
            ...capencyModalState,
            isOpen,
          }
        );
        if (!isOpen && elementId) {
          document.querySelector('#' + elementId).focus();
        }
      },
      closeCapencyModal: ({ setCapencyModalState }) => () => {
        setCapencyModalState({
          isLoading: false,
          isOpen: false,
          status: null,
          message: null,
          userEmail: null,
          handleSubmitForm: null,
        });
      },
      showCapencyModalWarn: ({ setCapencyModalState }) => ({
        message,
        value,
        elementId,
        handleSubmitForm,
      }) => {
        setCapencyModalState({
          isLoading: false,
          isOpen: true,
          status: 'warn',
          message,
          userEmail: value,
          handleSubmitForm,
          elementId,
        });
      },
      showCapencyModalError: ({ setCapencyModalState }) => ({
        message,
        value,
        elementId,
      }) => {
        setCapencyModalState({
          isLoading: false,
          isOpen: true,
          status: 'error',
          message,
          userEmail: value,
          handleSubmitForm: null,
          elementId,
        });
      },
    })
  );

export default EnhanceCapencyModal;